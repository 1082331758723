// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ci-visibility-js": () => import("./../src/pages/ci-visibility.js" /* webpackChunkName: "component---src-pages-ci-visibility-js" */),
  "component---src-pages-flaky-test-management-js": () => import("./../src/pages/flaky-test-management.js" /* webpackChunkName: "component---src-pages-flaky-test-management-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intelligent-test-runner-js": () => import("./../src/pages/intelligent-test-runner.js" /* webpackChunkName: "component---src-pages-intelligent-test-runner-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

